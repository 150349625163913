import React from 'react';
import { ArrowRight, DollarSign, Users, Target, Zap } from 'lucide-react';

const Slide = ({ title, children, className }) => (
  <div className={`bg-white rounded-lg shadow-lg p-6 mb-8 mx-auto max-w-3xl ${className}`}>
    <h2 className="text-2xl font-bold mb-4 text-indigo-800">{title}</h2>
    {children}
  </div>
);

const PitchDeck = () => (
  <div className="bg-gradient-to-br from-blue-50 to-purple-50 p-8 min-h-screen">
    <div className="max-w-4xl mx-auto">
      <Slide title="Reflect.AI: Your Personal Growth Companion" className="bg-indigo-100">
        <p className="text-xl text-indigo-700">AI-Powered Journaling for Meaningful Self-Reflection</p>
      </Slide>

      <Slide title="Problem">
        <ul className="list-disc pl-5 text-gray-700">
          <li>Existing journaling apps lack personalization</li>
          <li>Users struggle to maintain consistent journaling habits</li>
          <li>Generic prompts don't address individual growth needs</li>
          <li>Difficulty in tracking personal progress over time</li>
        </ul>
      </Slide>

      <Slide title="Solution">
        <ul className="list-disc pl-5 text-gray-700">
          <li>Highly personalized, AI-powered journaling experience</li>
          <li>Adaptive prompts and feedback tailored to individual goals</li>
          <li>LLM integration for deep insights and progress tracking</li>
          <li>Seamless, engaging user experience to encourage daily use</li>
        </ul>
      </Slide>

      <Slide title="Market Opportunity">
        <p className="mb-2"><DollarSign className="inline mr-2 text-green-600" /> Global personal development market: $38.28 billion in 2022</p>
        <p className="mb-2"><ArrowRight className="inline mr-2 text-blue-600" /> Projected CAGR of 5.5% from 2022 to 2030</p>
        <p><Users className="inline mr-2 text-purple-600" /> Target: Tech-savvy Gen Z and Millennials</p>
      </Slide>

      <Slide title="Product Features">
        <div className="grid grid-cols-2 gap-4 text-gray-700">
          <div><Zap className="inline mr-2 text-yellow-500" /> Personalized templates</div>
          <div><Zap className="inline mr-2 text-yellow-500" /> LLM-generated prompts</div>
          <div><Zap className="inline mr-2 text-yellow-500" /> Progress tracking</div>
          <div><Zap className="inline mr-2 text-yellow-500" /> AI-powered insights</div>
          <div><Zap className="inline mr-2 text-yellow-500" /> Mood tracking</div>
          <div><Zap className="inline mr-2 text-yellow-500" /> Customizable interface</div>
        </div>
      </Slide>

      <Slide title="Business Model">
        <p className="font-bold text-indigo-700 mb-2">Freemium model with premium subscription</p>
        <p className="mb-2">Free: Basic journaling features</p>
        <p className="mb-2">Premium ($9.99/month or $89.99/year):</p>
        <ul className="list-disc pl-5 text-gray-700">
          <li>Custom daily overviews</li>
          <li>Advanced analytics</li>
          <li>AI feedback</li>
          <li>Unlimited entries</li>
        </ul>
      </Slide>

      <Slide title="Financial Projections">
        <div className="grid grid-cols-3 gap-4 text-gray-700">
          <div>
            <p className="font-bold text-indigo-700">Year 1</p>
            <p><Users className="inline mr-2 text-blue-500" /> 100,000 users</p>
            <p><DollarSign className="inline mr-2 text-green-500" /> $500,000 revenue</p>
          </div>
          <div>
            <p className="font-bold text-indigo-700">Year 2</p>
            <p><Users className="inline mr-2 text-blue-500" /> 500,000 users</p>
            <p><DollarSign className="inline mr-2 text-green-500" /> $3 million revenue</p>
          </div>
          <div>
            <p className="font-bold text-indigo-700">Year 3</p>
            <p><Users className="inline mr-2 text-blue-500" /> 2 million users</p>
            <p><DollarSign className="inline mr-2 text-green-500" /> $15 million revenue</p>
          </div>
        </div>
      </Slide>

      <Slide title="Funding Ask" className="bg-green-50">
        <p className="text-xl font-bold text-green-700 mb-4">Seeking $2 million in seed funding</p>
        <div className="grid grid-cols-2 gap-4 text-gray-700">
          <div><Target className="inline mr-2 text-indigo-600" /> 40% Product development</div>
          <div><Target className="inline mr-2 text-indigo-600" /> 30% Marketing</div>
          <div><Target className="inline mr-2 text-indigo-600" /> 20% Team expansion</div>
          <div><Target className="inline mr-2 text-indigo-600" /> 10% Operations</div>
        </div>
      </Slide>

      <Slide title="Vision" className="bg-purple-50">
        <p className="text-xl italic text-purple-700">
          "Empowering millions to unlock their potential through AI-guided self-reflection and personal growth."
        </p>
      </Slide>
    </div>
  </div>
);

function App() {
  return <PitchDeck />;
}

export default App;